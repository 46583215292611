.btn {
    @apply rounded flex justify-center items-center text-center font-medium transition duration-150 ease-in-out;
}

.btn-sm {
    @apply text-sm px-4 py-3 leading-none;
}

.btn-md {
    @apply px-4 py-3.5 leading-none;
}

.btn-lg {
    @apply px-7 py-5 leading-none;
}

.btn-block {
    @apply w-full;
}

.btn-primary {
    @apply border ring-0 border-olive-900 hover:border-cyan-500 hover:cursor-pointer;
    @apply bg-olive-900 hover:bg-cyan-500 dark:bg-olive-100 dark:hover:bg-cyan-500;
    @apply active:bg-cyan-700 active:border-cyan-700;
    @apply text-olive-200 dark:text-olive-700 hover:text-olive-700 dark:hover:text-olive-700 active:text-olive-700 active:hover:text-olive-700;
}

.btn-secondary {
    @apply border ring-0 hover:border-cyan-500 hover:cursor-pointer;
    @apply border-olive-900 dark:active:border-cyan-700 dark:hover:border-cyan-500 dark:border-olive-700 active:border-cyan-700;
    @apply hover:bg-cyan-500 active:bg-cyan-700 dark:hover:bg-cyan-500 dark:active:bg-cyan-700;
    @apply bg-olive-100 dark:bg-olive-900;
    @apply text-olive-700 dark:text-olive-200 hover:text-olive-700 dark:hover:text-olive-700 active:text-olive-700 active:hover:text-olive-700;
}

.btn-disabled {
    @apply bg-slate-200 standard-text hover:cursor-not-allowed;
    pointer-events: none;
}

.btn-msp-top-bar {
    @apply ring-0 text-xs p-1 border rounded font-medium text-xs hover:cursor-pointer;
    @apply border-olive-950 active:border-cyan-700 hover:border-cyan-200 dark:border-olive-300 dark:hover:border-cyan-300 dark:active:border-cyan-700;
    @apply bg-olive-950 hover:bg-cyan-200 active:bg-cyan-700 dark:bg-olive-50 dark:hover:bg-cyan-300 dark:active:bg-cyan-700;
    @apply opposite-text hover:text-olive-900 active-standard-text;
    justify-content: normal;
    overflow: hidden;
}

.btn-menu {
    @apply flex flex-col items-center space-y-2 rounded inline-block text-center btn-md transition duration-150 ease-in-out;
    @apply bg-olive-100 hover:bg-olive-900 dark:bg-olive-900 dark:hover:bg-olive-100;
    @apply border border-olive-800 dark:border-olive-200;
    @apply standard-text hover-opposite-text active-opposite-text;
    font-weight: 300;
    font-size: 0.75rem;
}

.btn-menu-alt {
    @apply flex flex-row items-center space-x-4 rounded inline-block text-center btn-md transition duration-150 ease-in-out;
    @apply bg-olive-100 hover:bg-olive-900 dark:hover:bg-olive-100 dark:bg-olive-900;
    @apply standard-text hover-opposite-text active-opposite-text;
    @apply border border-olive-200 dark:border-olive-800;
    font-weight: 300;
    font-size: 0.75rem;
}

.btn-menu i {
    @apply light-text;
    font-size: 1rem;
}
// .btn-menu:hover i {
//     @apply opposite-text;
//     font-size: 1rem;
// }
.btn-menu-alt i {
    @apply light-text;
    font-size: 1rem;
}
// .btn-menu-alt:hover i {
//     @apply opposite-text;
//     font-size: 1rem;
// }

.btn-message {
    @apply float-right w-4 h-4 border-0 ring-0 flex items-center justify-center transition duration-150 ease-in-out;
    @apply dark:text-cyan-500;
    @apply hover:text-cyan-500 hover:cursor-pointer;
    @apply text-cyan-700 active-standard-text dark:text-cyan-500;
    @apply hover-opposite-text active-opposite-text;
    font-size: 0.875rem;
}

.pagination-button {
    @apply py-2 px-3 leading-tight;
    @apply text-olive-500 hover:text-olive-700 dark:text-olive-400 dark:hover:text-olive-200;
    @apply border border-olive-200 dark:border-olive-700;
    @apply bg-olive-50/30 dark:bg-olive-900/30 dark:hover:bg-olive-700;
}
.pagination-search {
    @apply py-2 px-3 leading-tight outline-none;
    @apply text-olive-500 hover:text-olive-700 dark:text-olive-400 dark:hover:text-olive-200;
    @apply border border-r-0 border-olive-200 dark:border-olive-700;
    @apply bg-olive-50/30 dark:bg-olive-900/30;
}
.pagination-search-input {
    @apply leading-tight outline-none;
    @apply text-olive-500 hover:text-olive-700 dark:text-olive-400 dark:hover:text-olive-200;
    @apply border border-r-0 border-olive-200 dark:border-olive-700;
    @apply bg-olive-50/30 dark:bg-olive-900/30;
}
.pagination-search-button {
    @apply py-2 pl-3 pr-1 leading-tight;
    @apply border border-l-0 border-olive-200 dark:border-olive-700;
    @apply text-olive-500 hover:text-olive-700 dark:text-olive-400 dark:hover:text-olive-200;
    @apply bg-olive-50/30 dark:bg-olive-900/30;
}
