.shadowdetect-wrapper {
    padding-top: max(env(safe-area-inset-top), 2.5rem);
    padding-right: env(safe-area-inset-right, 0);
    // padding-bottom: env(safe-area-inset-bottom, 0);
    padding-bottom: 0;
    padding-left: env(safe-area-inset-left, 0);
    min-height: calc(100vh - max(env(safe-area-inset-top), 2.5rem));
    @apply flex flex-col items-center gradient-dark-bg standard-text;
}
// .shadowdetect-wrapper .shadowdetect-navbar {
//     // padding-top: max(env(safe-area-inset-top), 2.5rem);
// }

.shadowdetect-wrapper-light {
    padding-top: max(env(safe-area-inset-top), 2.5rem);
    padding-right: env(safe-area-inset-right, 0);
    // padding-bottom: env(safe-area-inset-bottom, 0);
    padding-bottom: 0;
    padding-left: env(safe-area-inset-left, 0);
    min-height: calc(100vh - max(env(safe-area-inset-top), 2.5rem));
    @apply flex flex-col items-center gradient-light-bg standard-text;
}

.shadowdetect-wrapper-auth {
    padding: 0;
    min-height: 100vh;
    @apply flex flex-col items-center gradient-light-bg standard-text;
}

.shadowdetect-wrapper-index {
    padding: 0;
    min-height: 100vh;
    transition: background-image 0.5s ease-in-out;
    @apply flex flex-col items-center py-10 gradient-login-bg;
}

.shadowdetect-wrapper-dark {
    padding-top: max(env(safe-area-inset-top), 2.5rem);
    padding-right: env(safe-area-inset-right, 0);
    // padding-bottom: env(safe-area-inset-bottom, 0);
    padding-bottom: 0;
    padding-left: env(safe-area-inset-left, 0);
    min-height: calc(100vh - max(env(safe-area-inset-top), 2.5rem));
    @apply flex flex-col items-center py-10 gradient-login-bg;
}
.shadowdetect-container {
    @apply w-full flex flex-col pt-4;
    @apply flex flex-col items-center gradient-light-bg standard-text;
    min-height: calc(100vh - 84px - max(env(safe-area-inset-top), 2.5rem));
    // border-radius: 2rem 2rem 0 0;
}
.shadowdetect-container-dashboard {
    @apply w-full flex flex-col;
    @apply flex flex-col items-center gradient-light-bg standard-text;
    min-height: calc(100vh - 84px - max(env(safe-area-inset-top), 2.5rem));
    // border-radius: 2rem 2rem 0 0;
}
.shadowdetect-container-onboard {
    @apply w-full flex flex-col;
    @apply flex flex-col items-center gradient-light-bg standard-text;
    min-height: calc(100vh - max(env(safe-area-inset-top), 2.5rem));
}

.shadowdetect-content {
    @apply container mx-auto w-full flex flex-col items-center lg:px-0 px-5 pb-8;
    max-width: 768px;
}
.shadowdetect-content-full {
    @apply container mx-auto w-full flex flex-col items-center lg:px-0 px-5 pb-8;
}

.shadowdetect-block {
    @apply w-full flex-1 space-y-4 standard-text;
}
.device-expired {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply text-olive-500;
    }
    @apply text-olive-500;
}

.shadowdetect-vulnlist-snippet {
    @apply w-full standard-text rounded p-4 cursor-pointer flex flex-row justify-between items-center;
    backdrop-filter: blur(20px);
}
.shadowdetect-vulnlist-snippet.resolved {
    @apply bg-emerald-100 dark:bg-sky-500;
    backdrop-filter: blur(20px);
}
.shadowdetect-vulnlist-snippet.ignored {
    @apply bg-slate-500;
    backdrop-filter: blur(20px);
}

.shadowdetect-vulnlist {
    @apply w-full glass-effect standard-text rounded p-4 cursor-pointer flex flex-row justify-between items-center;
}
.shadowdetect-vulnlist:hover {
    // transform: scale(1.05);
    // animation: zoomer 0.2s ease-in-out;
}
.shadowdetect-vulnlist.resolved {
    @apply bg-emerald-100 dark:bg-sky-500;
}
.shadowdetect-vulnlist.ignored {
    @apply bg-slate-500;
}

.shadowdetect-msplist {
    @apply w-full glass-effect standard-text rounded flex flex-row justify-between items-center;
}
.shadowdetect-msplist > div {
    @apply p-4 cursor-pointer;
}
.shadowdetect-msplist .font-medium {
    // word-break: break-word;
}
.shadowdetect-msplist:hover {
    // transform: scale(1.05);
    // animation: zoomer 0.2s ease-in-out;
}
.shadowdetect-msplist.resolved {
    @apply bg-emerald-100 dark:bg-sky-500;
}
.shadowdetect-msplist.ignored {
    @apply bg-slate-500;
}

.shadowdetect-userlist {
    @apply w-full glass-effect standard-text rounded flex flex-row justify-between items-start;
}
.shadowdetect-userlist.resolved {
    @apply bg-emerald-100 dark:bg-sky-500;
}
.shadowdetect-userlist.ignored {
    @apply bg-slate-500;
}

.shadowdetect-devicebox {
    @apply w-full flex flex-col;
    padding: 0.5rem 0;
}
.shadowdetect-devicebox:hover {
    // transform: scale(1.05);
    // animation: zoomer 0.2s ease-in-out;
}

/*
[data-theme="light"]{
    .shadowdetect-devicebox:after {
        content: "";
        height: 0.5rem;
        // background: #f1f1f1;
        // background: rgb(230,219,237,1);
        // background: linear-gradient(58deg, rgba(230,219,237,1) 0%, rgba(230,219,237,1) 6%, rgba(255,255,255,1) 100%);
        width: 100%;
        margin: 0.5rem auto 0;
        border-radius: 1rem;
        box-shadow: inset 0 0 3px rgb(190,198,233);
        @apply gradient-light-bg;
    }
}
[data-theme="dark"]{
    .shadowdetect-devicebox:after {
        content: "";
        height: 0.5rem;
        // background: #f1f1f1;
        // background: rgb(2, 0, 36);
        // background: linear-gradient(158deg, rgba(2, 0, 36, 1) 0%, rgba(25, 55, 69, 1) 31%, rgba(68, 137, 168, 1) 100%);
        width: 100%;
        margin: 0.5rem auto 0;
        border-radius: 1rem;
        box-shadow: inset 0 0 3px rgb(190,198,233);
        @apply gradient-dark-bg;
    }
}
*/

.shadowdetect-whitebox {
    @apply w-full glass-effect p-5 rounded flex flex-col space-y-4;
}

.breadcrumb {
    @apply w-full;
}
.breadcrumb .breadcrumb-link {
    @apply text-link text-sm;
}

.qr-code svg {
    width: 100%;
}

@keyframes zoomer {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.05);
    }
}

.tab-user-on {
    @apply grow standard-text p-5 text-right;
}
.tab-user-off {
    @apply grow standard-text p-5 border-b dark:border-olive-950 text-right bg-gray-50 dark:bg-gray-900;
}
.tab-msp-on,
.tab-team-on {
    @apply standard-text p-5 border-l dark:border-olive-950;
}
.tab-msp-off,
.tab-team-off {
    @apply standard-text p-5 border-b border-l dark:border-olive-950 bg-gray-50 dark:bg-gray-900;
}
.tab-team-on {
    @apply standard-text p-5 border-l dark:border-olive-950 text-left;
}
.tab-team-off {
    @apply standard-text p-5 border-b border-l dark:border-olive-950 text-left bg-gray-50 dark:bg-gray-900;
}
.tab-data-on {
    @apply grow standard-text p-5 border-l dark:border-olive-950 text-left;
}
.tab-data-off {
    @apply grow standard-text p-5 border-b border-l dark:border-olive-950 text-left bg-gray-50 dark:bg-gray-900;
}
